<template>
  <div class="container mt-4">
    <b-col>
      <b-row>
        <b-col class="d-flex align-items-center">
          <BackButton></BackButton>
          <h5 class="ml-2 mb-0">
            {{ translations.statements.details.back_list }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card class="border-0" :header="translations.statements.details.page_title" body-class="px-0" header-class="border-0">
            <div class="col-12">
              <div class="row mb-5">
                <div class="col-3">
                  <b>{{ translations.statements.details.id }}</b>
                  <p>{{ details.id }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.statements.details.client_name }}</b>
                  <p>{{ details.client_name }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.statements.details.start_date }}</b>
                  <p>{{ formatDate(details.start_date) }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.statements.details.end_date }}</b>
                  <p>{{ formatDate(details.end_date) }}</p>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-3">
                  <b>{{ translations.statements.details.created_at }}</b>
                  <p>{{ formatDatetime(details.created_at) }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.statements.details.billing_type }}</b>
                  <p>{{ details.billing_type }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.statements.details.payer }}</b>
                  <p>{{ formatPayer(details) }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.statements.details.billing_period }}</b>
                  <p>{{ details.billing_period }}</p>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-3">
                  <b>{{ translations.statements.details.balance }}</b>
                  <p>{{ details.total_balance }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.statements.details.revenue_share }}</b>
                  <p>{{ details.revenue_share_balance }}</p>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-card
      class="border-0 mb-2 mt-3"
      body-class="px-0"
      header-class="border-0">
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          {{ translations.statements.summary.title }}
          <b-button variant="secondary" size="sm" @click="exportSummary">
            <feather type="file"></feather> {{ translations.statements.global.export_summary }}
          </b-button>
        </div>
      </template>
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="text-center">{{ translations.statements.summary.transaction_type }}</th>
              <th scope="col" class="text-center">{{ translations.statements.summary.unit }}</th>
              <th scope="col" class="text-center">{{ translations.statements.summary.quantity }}</th>
              <th scope="col" class="text-center">{{ translations.statements.summary.unit_value }}</th>
              <th scope="col" class="text-center">{{ translations.statements.summary.amount }}</th>
            </tr>
          </thead>
          <tbody v-if="details.summary && details.summary.items.length">
            <tr v-for="(item, trIndex) in details.summary.items" :key="trIndex">
              <td class="text-center">
                {{ item.type }}
                <span v-if="isPEPM(item.type)">
                  <feather
                    :id="`pepm_observations-${trIndex}`"
                    class="text-info"
                    type="info"></feather>
                  <b-tooltip
                    custom-class="pepm-tooltip"
                    :target="`pepm_observations-${trIndex}`"
                    placement="bottom">{{ item.observations }}</b-tooltip>
                </span>
              </td>
              <td class="text-center">{{ item.unit }}</td>
              <td class="text-center">{{ item.count }}</td>
              <td class="text-center">{{ item.value }}</td>
              <td class="text-center">{{ item.total }}</td>
            </tr>
            <tr v-if="hasSummaryFee">
              <td colspan="4" class="text-right">{{ translations.statements.summary.gross_amount }}:</td>
              <td class="text-center">{{ details.summary.total }}</td>
            </tr>
            <tr v-for="(item, trIndex) in details.fees.items" :key="`fee-${trIndex}`">
              <td colspan="3" class="text-right">({{ item.description }})</td>
              <td class="text-right">{{ item.fee_origin }}</td>
              <td class="text-center font-weight-bold">{{ item.fee_value }}</td>
            </tr>
            <tr v-if="hasSummaryFee">
              <td colspan="4" class="text-right font-weight-bold">{{ translations.statements.summary.total_amount }}:</td>
              <td class="text-center font-weight-bold">{{ details.net_balance }}</td>
            </tr>
            <tr v-else>
              <td colspan="4" class="text-right font-weight-bold">{{ translations.statements.summary.total_amount }}:</td>
              <td class="text-center font-weight-bold">{{ details.summary.total }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="5" class="text-center">{{ translations.statements.summary.empty }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <b-card
      class="border-0 mb-2 mt-3"
      body-class="px-0"
      header-class="border-0">
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          {{ translations.global.transactions }}
          <div>
            <b-button
              v-if="details && details.statement_configuration && details.statement_configuration.generate_external_claims_report"
              variant="secondary" class="compact-text mr-2" @click="exportExternalClaimsReport">
              <feather type="file-text"></feather>
              {{ translations.statements.global.export_external_claims_report }}
            </b-button>
            <export-transactions-button
              :filters="exportFilters"
              :filename="`statement_transactions_export`"
              :filenameSuffixes="filenameSuffixes"
              :button-label="translations.statements.global.export_details"/>
          </div>
        </div>
      </template>
      <financial-list
        v-model="currentPage"
        :header-data="TABLE_HEADERS"
        :data="transactions"
        :disabled="loadingPage"
        :items-per-page="itemsPerPage"
        @page-changed="loadTransactionsList"
        :total="total"
        :no-items-message="translations.transactions.missing_transactions"/>
    </b-card>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import ExportTransactionsButton from '@/components/Financial/ExportTransactionsButton.vue';
import translations from '@/translations';
import { formatDate, formatDatetime } from '@/helpers/finance';
import utils from '@/scripts/tools/utils';

export default {
  name: 'FinanceStatementsDetails',
  components: {
    BackButton,
    FinancialList,
    ExportTransactionsButton,
  },
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.transactions.transaction_id, 'id'),
      associateHeaderDataItem(this.translations.transactions.program_id, 'patient_id'),
      associateHeaderDataItem(this.translations.transactions.patient_name, 'member_full_name'),
      associateHeaderDataItem(this.translations.transactions.unit, 'unit'),
      associateHeaderDataItem(this.translations.transactions.therapy, 'therapy_name'),
      associateHeaderDataItem(this.translations.transactions.transaction_type, 'transaction_type'),
      associateHeaderDataItem(this.translations.transactions.value, 'transaction_value'),
      associateHeaderDataItem(this.translations.transactions.value_type, 'transaction_value_type'),
      associateHeaderDataItem(this.translations.transactions.partner_name, 'partner_name'),
    ];
    this.statementID = Number(this.$route.params.statementID);
    this.exportFilters = {
      statement_id: this.statementID,
      order_by: 'id',
    };
  },
  data() {
    return {
      translations: translations.finance,
      details: {},
      transactions: [],
      total: 0,
      currentPage: 1,
      itemsPerPage: 5,
      loadingPage: true,
      hasSummaryFee: false,
      filenameSuffixes: [],
    };
  },
  async beforeMount() {
    this.loadingPage = true;
    await this.loadStatement();
    await this.loadTransactionsList();
    this.loadingPage = false;
  },
  methods: {
    async changePage(page) {
      this.loadingPage = true;
      this.currentPage = page;
      await this.loadTransactionsList();
      this.loadingPage = false;
    },
    formatDate,
    formatDatetime,
    formatPayer(details) {
      return details?.statement_configuration?.payer?.payer_name || '-';
    },
    async loadStatement() {
      try {
        const { statementID } = this;
        const res = await this.$store.dispatch('Financial/getStatement', { statementID });
        this.details = res.data.data;
        if (this.details.fees && this.details.fees.items && this.details.fees.items.length) {
          this.hasSummaryFee = true;
        }
        this.filenameSuffixes = [ statementID, this.details.client_name.replace(/\s/g, '_') ];
      } catch (err) {
        let errDetail = err;
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.statements.errors.fetch_details_error} ${errDetail}`;
        this.$noty.error(errMsg);
      }
    },
    async loadTransactionsList() {
      const { statementID } = this;
      const filters = {
        statement_id: statementID,
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        order_descending: true,
        order_by: 'id',
      };

      const apiTransactions = await this.$store.dispatch('Financial/getTransactions', filters);

      if (!apiTransactions) {
        this.total = 0;
        this.transactions = [];
      } else {
        this.total = apiTransactions.total;
        this.transactions = apiTransactions.transactions;
      }
      this.transactions.forEach(transaction => {
        transaction.created_at = formatDatetime(transaction.created_at);
      });
    },
    async exportSummary() {
      try {
        const { statementID } = this;
        const { data } = await this.$store.dispatch('Financial/exportStatementSummary', { id: statementID });
        const clientName = this.details.client_name.replace(/\s/g, '_');
        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `summary_${dateStr}_${statementID}_${clientName}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    async exportExternalClaimsReport() {
      try {
        const { statementID } = this;
        const { data } = await this.$store.dispatch('Financial/exportExternalClaimsReport', { statement_id: statementID });
        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `external_claims_report_${dateStr}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.statements.errors.export_external_claims_report_generic;
        if (error.response && error.response.status === 404) {
          errorMsg = this.translations.statements.errors.export_external_claims_report_not_available;
        }
        this.$noty.error(errorMsg);
      }
    },
    isPEPM(type) {
      return (type || '').toLowerCase() === 'pepm_fee';
    },
  },
};
</script>
<style>
  #pepm_observations {
    height: 18px;
    width: 18px;
  }

  .pepm-tooltip > .tooltip-inner {
    white-space: pre-line;
    max-width: none !important;
  }
</style>
